
.background{
  background-color: antiquewhite;
  height: 100vh;
  width: 100vw;
  position: relative;
  
}


.loader {
  margin: 0;
  position: absolute;
  top: 40%;
  transform: translate(-50%, -50%);
  border: 16px solid #f3f3f3;
  /* Light grey */
  /* border-top: 16px solid #048023; */
  border-top: 16px solid #b07e4d;
  /* Dark Green */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spinloader 2s linear infinite;

 
}

@media only screen and (min-width: 600px)  {
  .loader {
  left: 46%;
  }
}

@media only screen and (max-width: 600px)  {
  .loader {
  left: 30%;
  }
}

.loader img {
  height: 100%;
  width: 100%;
  animation: spinlogo 2s linear infinite;
}

@keyframes spinloader {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

@keyframes spinlogo {
  0% {
      transform: rotate(360deg);
  }

  100% {
      transform: rotate(0deg);
  }
}