$main-font: 'Yanone Kaffeesatz',
sans-serif;
$new-white: rgb(248, 246, 234);
$text-margin: 10px;

body {
    height: 100%;
    width: 100%;
    margin: 0;
    background: rgb(209, 182, 214);
    font-family: $main-font;
    position: absolute;
    box-sizing: border-box;
    #root {
        height: 100%;
        .page-container {
            position: relative;
            width: 100%;
            height: 100%;
            display: block;
            perspective: 1000px;
            .card-container {
                height: 350px;
                margin: 0;
                padding: 0;
                top: 20px;
                width: 330px;
                margin-left: 35%;
              margin-right: 35%;
                position: relative;
                transform-style: preserve-3d;
                box-sizing: border-box;
                &.flipped {
                    .front {
                        transform: rotateY(180deg);
                    }
                    .back {
                        transform: rotateY(0deg);
                    }
                }
                .front,
                .back {
                    box-sizing: border-box;
                    height: 100%;
                    width: 100%;
                    display: block;
                    background: $new-white;
                    box-shadow: 10px 10px 5px rgb(95, 77, 99);
                    padding: 10px;
                    position: absolute;
                    border-radius: 10px;
                    backface-visibility: hidden;
                    transform-style: preserve-3d;
                    transition: -webkit-transform ease 500ms;
                    transition: transform ease 500ms;
                }
                .front {
                    z-index: 2;
                    transform: rotateY(0deg);
                    /* front tile styles go here! */
                }
                .back {
                    background: $new-white;
                    transform: rotateY(-180deg);
                    padding: 20px;
                    font-size: 20px;
                    /* back tile styles go here! */
                }
                .image-container {
                    position: relative;
                    .card-image {
                        width: 40%;
                        border-radius: 10px;
                        margin-top: 21%;
                        margin-left: 31%;
                    }
                    .title {
                        font-size: 34px;
                        position: absolute;
                        margin-bottom: $text-margin;
                        margin-left: $text-margin;
                        top:120%;
                        bottom: 0;
                        left: 'black';
                    }
                }
                .main-area {
                    margin-left: $text-margin;
                    margin-top: $text-margin;
                    margin-right: $text-margin;
                    
                    .blog-post {
                        height: 100%;
                        
                        .date {
                        margin-top: $text-margin;
                        margin-bottom: 5px;
                        font-size: 20px;
                    }
                    .read-more {
                        font-size: 20px;
                        position: relative;
                        bottom: 0px;
                        text-align: right;
                    }
                        .blog-content {
                            height: 90%;
                            display: block;
                            margin-top: $text-margin;
                            margin-bottom: $text-margin;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }
        // footer {
        //     position: fixed;
        //     bottom: 0;
        //     right: 0;
        //     margin: 20px;

        // }
    }
}

